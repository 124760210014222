import { Link } from "react-router-dom";
const Card = ({ product }) => {
  // const navigate = useNavigate();

  return (
    <div className=" w-full  shadow-md rounded-md overflow-hidden">
      <Link to={`/productdetails/${product.id}`}>
        <div className=" img  h-[20rem] flex  justify-center items-center  overflow-hidden">
          <img
            src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
            alt="mehedi product"
            className="max-w-full max-h-full w-full h-full object-cover"
          />
        </div>
      </Link>

      <div className="p-2 flex flex-col">
        <Link to={`/productdetails/${product.id}`}>
          <h3 className="text-[1rem] truncate  font-normal text-[#212121] mb-2 overflow-hidden">
            {product?.name}
          </h3>
        </Link>
        <Link
          to={`/productdetails/${product.id}`}
          className="text-[#9A2A55] font-semibold "
        >
          <div className="flex  items-center font-semibold">
            {+product.after_discount > 0 ? (
              <div className="flex justify-between items-center text-center gap-2">
                <span className="font-semibold line-through text-[#9A2A55] decoration-200">
                  ৳{product?.unit_price}
                </span>
                <span className="">৳{+product?.after_discount}</span>
              </div>
            ) : (
              <div className="flex items-center justify-center">
                ৳{product?.unit_price}
              </div>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Card;
