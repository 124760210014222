import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, HashNavigation, Navigation } from "swiper";
import { useSelector } from "react-redux";
// const imgData = [
//   // {
//   //   id: 3,
//   //   img: "/banner/1674449061.jpg",
//   // },
//   {
//     id: 1,
//     img: "/banner/1674900333.jpg",
//   },
//   {
//     id: 2,
//     img: "/banner/1674905858.jfif",
//   },
//   {
//     id: 3,
//     img: "/banner/1674907188.jfif",
//   },
// ];

const BannerSlider = () => {
  const { items: data } = useSelector((state) => state.bannerSlider);

  return (
    <div className="banner-slider-frame ">
      <div className="banner-slider  ">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={30}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          speed={700}
          hashNavigation
          loop={true}
          modules={[Autoplay, Navigation, HashNavigation]}
          className="mySwiper h-full"
        >
          {data?.map((image) => (
            <SwiperSlide key={image?.id}>
              <div
                key={image?.id}
                className="w-full  md:h-[30rem] h-[12rem] 2xl:h-[46.5rem] overflow-hidden"
              >
                <img
                  key={image?.id}
                  src={`${process.env.REACT_APP_URL}/public/uploads/slider/${image?.slider}`}
                  alt={image?.alt}
                  className={` max-w-full max-h-full w-full h-auto  transition-opacity duration-500 object-cover object-center`}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BannerSlider;
